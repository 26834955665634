import { array } from 'prop-types'
import React from 'react'
import ArticleItem from '../ArticleItem'
import { groupSubNavs } from '../lib/group-sub-navs'
import SubNavItem from '../SubNavItem'
import styles from './Learning.styl'

const Learning = ({ subNavs, articles }) => {
  var navGroups = groupSubNavs(subNavs, 3)

  return (
    <div className={styles.Container}>
      <div className={styles.NavItemContainer}>
        {navGroups.map((group, index) => (
          <div key={`Group-${index}`} className={styles.SubNavList}>
            {group.map(item => (
              <SubNavItem
                key={`SecondaryLink-Learning-${item.url}`}
                url={item.url}
                label={item.title || item.text}
                fullHeight
              />
            ))}
          </div>
        ))}
      </div>
      {articles && articles.length > 0 && (
        <div className={styles.Articles}>
          {articles.map(article => (
            <ArticleItem
              key={article._id}
              shortHeadline={article.shortHeadline}
              imageUrl={article.imageUrl}
              slug={article.slug}
              fullUrlPath={article.__fullUrlPath}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default Learning

Learning.propTypes = {
  subNavs: array,
  articles: array
}
